
.appHeader{
    
    padding-top: 20px;
    border-bottom: solid 1px rgb(240, 240, 240);
    background-color: white;
}

.body{
    background-color: white;
}


.navLogo{
    border-radius: 8px;

}

.navTitle{
    line-height: 100%;    
}

.navButton{
    font-size: 1rem;
}


.appContent{
    background-color: white;
}

.footText{
    color: black;
}